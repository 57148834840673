import clsx from 'clsx';

interface Props {
  direction: 'horizontal' | 'vertical';
  className?: string;
  gradient?: string;
}

const Line = ({ direction, gradient, className }: Props) => (
  <div
    className={clsx(
      direction === 'horizontal' ? `bg-gradient-to-r h-[0.8px]` : `bg-gradient-to-t w-[0.8px]`,
      gradient,
      className
    )}
  />
);

export default Line;
