'use client';

import { useRef } from 'react';
import Slider from 'react-slick';
import { Button, Icon } from '@/components';
import { Card } from './Card';
import { JacksTestimonial, Jumpman, PrideBetTestimonial, ArrowRight } from '@/assets';

const settings = {
  speed: 500,
  swipeToSlide: false,
  variableWidth: false,
  touchThreshold: 100,
  infinite: true,
  slidesToShow: 3,
  mobileFirst: true,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 8000,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 2,
        autoplay: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const QUOTES = [
  {
    text: "We use Splash to activate and engage customers across the Jacks platform and couldn't be happier with both the product and service they provide.",
    logo: JacksTestimonial,
    author: 'Ronnie Broersma',
    role: 'Head of Sportsbook',
    partner: 'Jacks',
  },
  {
    text: 'We have loved working with Splash since the launch of the popular & successful Rewards Reel and look to forward to more fun & games in the future!',
    logo: Jumpman,
    author: 'Cheli Aflalo',
    role: 'Director of CRM',
    partner: 'Jumpman Gaming',
  },
  {
    text: "We use Splash as part of PrideBet's retention strategy and it works. Splash provides exceptional service & technical support, ensuring a seamless experience for both us and our players. We highly recommend Splash.",
    logo: PrideBetTestimonial,
    author: 'Riaan Slabbert',
    role: 'Operations Director',
    partner: 'PrideBet',
  },
];

export const Swiper = () => {
  const slider = useRef<Slider>(null);

  const NavArrows = () => (
    <div className='mx-auto gap-12 w-min mt-[56px] md:mt-[32px] hidden xl:flex'>
      <Button
        size='small'
        onClick={() => slider?.current?.slickPrev()}
        variant='icon'
        className='text-neutral-0 relative z-10'>
        <Icon className='fill-neutral-0 rotate-180' Component={ArrowRight} width={24} height={24} />
      </Button>
      <Button
        size='small'
        onClick={() => slider?.current?.slickNext()}
        variant='icon'
        className='text-neutral-0 relative z-10'>
        <Icon className='fill-neutral-0' Component={ArrowRight} width={24} height={24} />
      </Button>
    </div>
  );

  return (
    <>
      <Slider
        ref={slider}
        {...settings}
        className='w-full mt-[56px] max-w-[1158px] xl:max-w-[850px] mx-auto relative z-20 pointer-events-none xl:pointer-events-auto'>
        {QUOTES.map(props => (
          <Card key={props.author} {...props} />
        ))}
      </Slider>
      <NavArrows />
    </>
  );
};
