import clsx from 'clsx';
import { AnimatedComponent, Typography } from '@/components';

interface Props {
  isVisible: boolean;
  className: string;
}

export const ChooseGame = ({ isVisible, className }: Props) => (
  <AnimatedComponent
    animate={{ translateY: [-23, -15, -23], transition: { duration: 3, repeat: Infinity } }}
    className={clsx(
      'text-secondary absolute top-[110px] md:right-[150px] sm:right-[140px] transition-opacity duration-200',
      className,
      isVisible ? 'opacity-100' : 'opacity-0'
    )}>
    <AnimatedComponent
      animate={{ translateY: [-3, 3, -3], transition: { duration: 3, repeat: Infinity } }}
      className='absolute ease-in-out -top-[20px] -right-[23px] z-0 mx-auto flex items-center justify-center w-[36px] h-[36px] bg-secondary-transparent-20 rounded-full backdrop-blur-lg shadow-guide'>
      <AnimatedComponent
        animate={{ scale: [0.9, 1.2, 0.9], transition: { duration: 1, repeat: Infinity } }}
        className='z-10 bg-secondary w-[12px] h-[12px] rounded-full'
      />
    </AnimatedComponent>
    <Typography className='relative z-30 w-max' tag='p' size='bodyMedium'>
      Play
    </Typography>
  </AnimatedComponent>
);
