import { SVGProps } from '@/interfaces/SVGProps';

const Linkedin = ({ className, width = 25, height = 24 }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 25 24'
    fill='none'
    className={className}>
    <g clipPath='url(#a)'>
      <path d='M20.75 2.25H4.25a1.5 1.5 0 0 0-1.5 1.5v16.5a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V3.75a1.5 1.5 0 0 0-1.5-1.5Zm0 18H4.25V3.75h16.5v16.5ZM9.5 10.5v6a.75.75 0 1 1-1.5 0v-6a.75.75 0 1 1 1.5 0Zm8.25 2.625V16.5a.75.75 0 1 1-1.5 0v-3.375a1.875 1.875 0 1 0-3.75 0V16.5a.75.75 0 1 1-1.5 0v-6a.75.75 0 0 1 1.48-.167 3.375 3.375 0 0 1 5.27 2.792Zm-7.875-5.25a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path d='M24.5 24V0H.5v24z' />
      </clipPath>
    </defs>
  </svg>
);
export default Linkedin;
