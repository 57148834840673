export const NAV_ITEMS = {
  LEFT: [
    { href: '#work', label: 'Why Splash' },
    { href: '/#partners', label: 'Partners' },
    { href: '/#testimonials', label: 'Testimonials' },
  ],
  RIGHT: [
    { href: '/#features', label: 'Features' },
    { href: '/#team', label: 'Team' },
    { href: 'mailto:sales@splash.tech?subject=Hello!', label: 'Contact us' },
  ],
};
