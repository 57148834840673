import Image from 'next/image';
import { Button, Icon, Link, Typography } from '@/components';
import { Stickers } from './Stickers';
import { AltLogo, Linkedin, X, ArrowRight } from '@/assets';
import { NAV_ITEMS } from '@/constants/NavItems';

const SOCIAL_LINKS = [
  {
    component: Linkedin,
    href: 'https://linkedin.com/company/splash-technology-uk',
    name: 'linkedin',
  },
  {
    component: X,
    href: 'https://twitter.com/splashtech',
    name: 'twitter',
  },
];

const Footer = () => {
  return (
    <footer className='text-neutral-0'>
      <div className='relative overflow-hidden'>
        <Stickers />
        <div className='py-12 pl-[160px] md:pl-6 border-b-neutral-0 border-b-[1px]'>
          <Image src={AltLogo} width={113} alt='Splash Tech' />
        </div>
        <div className='flex md:flex-col'>
          <ul
            className='flex flex-col gap-[32px] md:gap-[16px] w-[356px] md:w-full py-[66px] md:py-[40px] pl-[160px] md:pl-6
            border-r-[1px] border-r-neutral-0 md:border-b-neutral-0 md:border-r-0 md:border-b-[1px]'>
            {[...NAV_ITEMS.LEFT, ...NAV_ITEMS.RIGHT].slice(0, -1).map(({ href, label }) => (
              <li key={label}>
                <Link href={href} weight='medium' size='bodySmall'>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
          <div className='px-[104px] md:px-6 flex items-center md:py-[32px] relative z-10'>
            <div className='max-w-[309px] md:max-w-[240px]'>
              <Typography className='mb-2 md:text-2xl-mobile' tag='h6' size='1xl'>
                Interested in working with us?
              </Typography>
              <Typography tag='p' size='bodySmall'>
                sales@splash.tech
              </Typography>
              <a
                href='mailto:sales@splash.tech?subject=Hello!'
                target='_self'
                className='relative z-20 inline-block mt-[44px] rounded-full'>
                <Button rightIcon={ArrowRight}>Contact Us</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='flex md:flex-col items-center justify-between md:items-start px-[160px] md:px-[24px] py-12 md:py-8 border-t-neutral-0 border-t-[1px]'>
        <div className='flex gap-[32px]'>
          {SOCIAL_LINKS.map(({ href, component, name }) => (
            <a key={href} href={href} target='_blank' aria-label={name} rel='noreferrer'>
              <Icon
                Component={component}
                className='fill-neutral-0 cursor-pointer transition-all duration-200 ease-in-out hover:fill-secondary'
              />
            </a>
          ))}
        </div>
        <Typography className='md:mt-6' tag='p' size='bodyExtraSmall'>
          © 2024 all rights reserved
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
