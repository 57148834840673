import Image from 'next/image';
import { AnimatedComponent } from '@/components';
import { Feature } from '../Feature';
import { Phone } from './Phone';
import { ColorWheelLottie } from './ColorWheelLottie';
import { ArrowCursor } from '@/assets';

export const Solution = () => (
  <Feature
    className='relative bg-gradient-to-r from-primary-200 to-primary md:from-secondary md:to-primary-200'
    title='Turnkey Solution'>
    <Phone />
    <div className='absolute top-[133px] left-[88px] z-0 lg:top-auto md:top-[100px] lg:bottom-[115px] lg:left-[100px] md:left-[20px] lg:w-[175px]'>
      <ColorWheelLottie />
    </div>
    <AnimatedComponent
      className='absolute bottom-[25px] left-[135px] z-10 lg:hidden'
      initial={{ opacity: 0, translateX: -20, translateY: 20 }}
      whileInView={{
        opacity: 1,
        translateX: 0,
        translateY: 0,
        transition: { duration: 0.8, type: 'spring', delay: 0 },
      }}
      animate={{
        x: [0, 7, 0],
        y: [0, -7, 0],
        transition: {
          duration: 3,
          repeat: Infinity,
          delay: 1.5,
          type: 'tween',
        },
      }}>
      <Image width={73} src={ArrowCursor} alt='Arrow cursor' />
    </AnimatedComponent>
  </Feature>
);
