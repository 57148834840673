'use client';

import React, { MutableRefObject, Suspense, useEffect, useState } from 'react';
import { Application } from '@splinetool/runtime';
import { Loader } from '@/components';

const Spline = React.lazy(() => import('@splinetool/react-spline'));

const CANVAS_ID = 'workWithUsCanvas';
const CANVAS_URL = 'https://prod.spline.design/psazuipseYnm8bBi/scene.splinecode';

export const SplineCanvas = ({ spline }: { spline: MutableRefObject<Application | null> }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleOnLoad = (loadedSpline: Application) => {
    spline.current = loadedSpline;
  };

  return (
    <>
      <Suspense>
        {!isLoading && (
          <Spline
            className='block z-10 bg-transparent pointer-events-none scale-[1.1] md:scale-[1.05]'
            onLoad={handleOnLoad}
            id={CANVAS_ID}
            scene={CANVAS_URL}
            renderOnDemand
          />
        )}
      </Suspense>
      <Loader isSplineLoaded={!isLoading} />
    </>
  );
};
