'use client';

import { useRef } from 'react';
import Image from 'next/image';
import Slider from 'react-slick';
import { Button, Icon, Typography } from '@/components';
import {
  Jose,
  Adam,
  Arthur,
  Marc,
  Marko,
  Oliver,
  Ricky,
  Ben,
  Chris,
  Yannis,
  ArrowRight,
  Iryna,
  Bonham,
} from '@/assets';

const settings = {
  speed: 400,
  swipeToSlide: false,
  variableWidth: false,
  touchThreshold: 100,
  infinite: true,
  mobileFirst: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
};

const AVATARS = [
  {
    src: Adam,
    name: 'Adam Wilson',
    role: 'CEO',
  },
  {
    src: Jose,
    name: 'José Silva',
    role: 'Head of Design',
  },
  {
    src: Ben,
    name: 'Ben Starr',
    role: 'Chairman',
  },
  {
    src: Arthur,
    name: 'Arthur Embleton',
    role: 'Developer',
  },
  {
    src: Marc,
    name: 'Marc Blair',
    role: 'Content Director',
  },
  {
    src: Marko,
    name: 'Marko Stjepanek',
    role: 'Developer',
  },
  {
    src: Oliver,
    name: 'Oliver Renner',
    role: 'CTO',
  },
  {
    src: Ricky,
    name: 'Ricky Lawrence',
    role: 'Customer Success',
  },
  {
    src: Chris,
    name: 'Chris Kape',
    role: 'Director',
  },
  {
    src: Yannis,
    name: 'Yannis Aivalakis',
    role: 'Program Director',
  },
  {
    src: Iryna,
    name: 'Iryna Yershova',
    role: 'Developer',
  },
  {
    src: Bonham,
    name: 'Bonham Forshage',
    role: 'Regional Director Asia',
  },
];

export const Swiper = () => {
  const slider = useRef<Slider>(null);

  const handleNextClick = () => {
    slider?.current?.slickNext();
  };

  const handlePrevClick = () => {
    slider?.current?.slickPrev();
  };

  const NavArrows = () => (
    <div className='w-full mt-[54px] flex justify-between px-[160px] md:px-[24px]'>
      <Button size='small' onClick={handlePrevClick} variant='icon' className='text-neutral-0 relative z-10'>
        <Icon className='fill-neutral-0 rotate-180' Component={ArrowRight} width={24} height={24} />
      </Button>
      <Button size='small' onClick={handleNextClick} variant='icon' className='text-neutral-0 relative z-10'>
        <Icon className='fill-neutral-0' Component={ArrowRight} width={24} height={24} />
      </Button>
    </div>
  );

  return (
    <>
      <Slider
        ref={slider}
        {...settings}
        className='[&>div]:overflow-visible [&>div]:max-w-[290px] md:[&>div]:max-w-[195px] ml-[90px] md:ml-[24px] w-full mt-[56px] min-w-[1984px] mx-auto'>
        {AVATARS.map(({ src, name, role }) => (
          <div
            key={name}
            className='relative rounded-[10px] p-2 bg-neutral-900 h-[292px] md:h-[245px] max-w-[256px] md:max-w-[177px] mr-8 group'>
            <Image
              className='rounded-[10px] h-[276px] md:h-[229px] object-cover object-center grayscale md:grayscale-0 transition-grayscale duration-200 group-hover:grayscale-0'
              src={src}
              alt={name}
            />
            <div className='absolute -bottom-[16px] md:bottom-auto md:top-[200px] -right-[15px] md:right-0 md:left-0 md:mx-auto z-20 flex justify-center pointer-events-none'>
              <div className='inline-block text-secondary bg-neutral-900 py-2 px-4 rounded-[9px] shadow-2xl opacity-0 md:opacity-100 group-hover:opacity-100 transition duration-200'>
                <Typography className='md:text-body-sm font-bold' tag='p' size='bodyMedium'>
                  {name}
                </Typography>
                <Typography className='md:text-body-sm' tag='p' size='bodyMedium' weight='normal'>
                  {role}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <NavArrows />
    </>
  );
};
