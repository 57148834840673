import { SVGProps } from '@/interfaces/SVGProps';

const X = ({ className, width = 25, height = 24 }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 25 24'
    className={className}>
    <g clipPath='url(#a)'>
      <path d='m14.315 10.508 7.1-8.253h-1.683l-6.165 7.166-4.923-7.166h-5.68l7.446 10.836-7.445 8.654h1.682l6.51-7.567 5.2 7.567h5.679l-7.722-11.237Zm-2.305 2.678-.754-1.079-6.002-8.585h2.584l4.844 6.929.754 1.079 6.297 9.006h-2.585l-5.138-7.35Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path d='M24.5 24V0H.5v24z' />
      </clipPath>
    </defs>
  </svg>
);
export default X;
