'use client';

import Lottie from 'lottie-react';
import * as colorWheelLottieData from '@/assets/lotties/colorWheel.json';

export const ColorWheelLottie = () => {
  return (
    <div className='w-[250px] lg:w-[200px]'>
      <Lottie animationData={colorWheelLottieData} width={400} loop />
    </div>
  );
};
