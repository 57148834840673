'use client';

import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import { BarLoader } from 'react-spinners';
import { MainLogo } from '@/assets';

const Loader = ({ isSplineLoaded }: { isSplineLoaded: boolean }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    if (isSplineLoaded) {
      setTimeout(() => {
        setIsLoading(false);
        setIsFading(false);
        document.body.style.overflow = 'unset';
      }, 4300);

      setTimeout(() => {
        setIsFading(true);
      }, 4000);
    }
  }, [isSplineLoaded]);

  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex items-center justify-center fixed w-screen h-screen left-0 right-0 bottom-0 top-0 bg-neutral-900 z-[200] transition-opacity duration-500',
        isFading && 'opacity-0'
      )}>
      <div className='w-[274px] flex flex-col items-center justify-center'>
        <Image className='w-[196px] h-auto mb-[65px]' priority src={MainLogo} alt='Splash Tech' />
        <BarLoader className='' color='#81FDB7' width='100%' loading />
      </div>
    </div>
  );
};

export default Loader;
