import clsx from 'clsx';
import { Icon } from '@/components';

interface Props {
  children: React.ReactNode;
  variant?: 'primary' | 'icon';
  isDisabled?: boolean;
  hideContainer?: boolean;
  size?: 'large' | 'small';
  className?: string;
  onClick?: () => void;
  rightIcon?: ({ className }: { className?: string }) => JSX.Element;
}

export const Button = ({
  children,
  rightIcon,
  onClick,
  className,
  size = 'large',
  isDisabled = false,
  variant = 'primary',
  hideContainer = false,
}: Props) => {
  const containerClasses = {
    base: `bg-gradient-to-tr rounded-full p-[2px]
    ${isDisabled ? 'from-neutral-300 to-neutral-300' : 'from-primary to-secondary'} 
    ${hideContainer && 'from-transparent to-transparent'}`,
    primary: '',
    icon: size === 'large' ? 'w-[56px] md:w-[44px]' : 'w-[44px]',
  };

  return (
    <div className={clsx(containerClasses.base, containerClasses[variant], className)}>
      <button
        onClick={onClick}
        type='button'
        disabled={isDisabled}
        className={clsx(
          'transition-all duration-200 ease-in-out rounded-full w-full flex items-center gap-3 justify-center bg-neutral-900  hover:bg-transparent text-neutral-0 disabled:text-neutral-300 disabled:bg-neutral-500 text-body-sm lg:text-body-xs md:text-body-md',
          {
            'p-[14px] md:p-[8px]': variant === 'icon' && size === 'large',
            'px-[10px] py-[8px] h-[40px]': variant === 'icon' && size === 'small',
            'px-[26px] py-[8.5px]': variant === 'primary',
          },
          hideContainer && 'bg-transparent disabled:bg-transparent'
        )}>
        {children}
        {rightIcon && (
          <Icon
            width={20}
            height={20}
            Component={rightIcon}
            className={`${isDisabled ? 'fill-neutral-300' : 'fill-neutral-0'}`}
          />
        )}
      </button>
    </div>
  );
};

export default Button;
