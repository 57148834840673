import Image from 'next/image';
import { Typography } from '@/components';
import { Logos } from './Logos';
import { LinesDecorations } from './LinesDecorations';
import { PartnersBackground } from '@/assets';

const Partners = () => (
  <section
    id='partners'
    className='text-neutral-0 relative overflow-hidden pb-[338px] md:pb-[204px] pt-[60px] 2xl:pt-0'>
    <Image
      className='left-0 right-0 w-full -top-[200px] mx-auto absolute z-0 h-[1200px] min-w-[1440px] md:min-w-0 md:object-cover md:object-center'
      src={PartnersBackground}
      alt=' '
    />
    <LinesDecorations />
    <div className='relative z-10 mt-[60px] md:mt-0 flex flex-col items-center'>
      <Typography tag='h2' size='2xl' className='md:max-w-[300px] text-center mb-8 md:mb-4'>
        Platform & Integration Partners
      </Typography>

      <Typography tag='p' size='bodyMedium' className='max-w-[650px] md:max-w-[328px] md:text-body-sm text-center'>
        Splash Tech is integrated into many of the biggest casino and sportsbook providers in the industry, enabling
        seamless onboarding and product launches.
      </Typography>

      <Logos />
    </div>
  </section>
);

export default Partners;
