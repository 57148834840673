'use client';

import { useEffect, useState, useRef } from 'react';
import Image, { StaticImageData } from 'next/image';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import confetti from 'canvas-confetti';
import { clsx } from 'clsx';
import { AnimatedComponent } from '@/components';
import { GuideButton } from './GuideButton';
import * as slotsSpinning from '@/assets/lotties/slotsSpinning.json';
import { STEPS, GameType } from './utils';

interface Props {
  showGameScreen: boolean;
  gameStarted: boolean;
  currentStepData: Step;
  activeStep: number;
  selectedGame: GameType;
  onAdvanceStep: (nextStep: number) => void;
}

type Step =
  | {
      key: number;
      image: StaticImageData;
    }
  | undefined;

const advanceStepClasses = {
  [GameType.SPORTS]: [
    'bottom-[145px] md:bottom-[135px] w-[152px] h-[34px] left-0 right-0 rounded-full',
    'bottom-[236px] md:bottom-[222px] right-0 left-0 w-[193px] md:w-[185px] h-[25px] md:h-[24px] rounded-[10px]',
    'bottom-[126px] md:bottom-[118px] right-0 left-0 w-[174px] h-[30px] rounded-[10px]',
    'bottom-[230px] md:bottom-[212px] left-0 right-0 w-[152px] h-[33px] rounded-full',
  ],
  [GameType.CASINO]: [
    'bottom-[191px] md:bottom-[185px] left-0 md:left-0 right-0 w-[130px] md:w-[122px] h-[33px] md:h-[31px] rounded-full',
    '',
    'bottom-[152px] md:bottom-[146px] left-0 right-0 w-[128px] md:w-[122px] h-[33px] md:h-[30px] rounded-full',
  ],
};

const screenPositions = {
  [GameType.SPORTS]: [
    'top-[115px] md:top-[142px] w-[233px] md:w-[221px]',
    'top-[115px] md:top-[142px] w-[233px] md:w-[221px]',
    'top-[115px] md:top-[142px] w-[233px] md:w-[221px]',
    'top-[115px] md:top-[142px] w-[233px] md:w-[221px]',
  ],
  [GameType.CASINO]: [
    'top-[120px] md:top-[142px] w-[238px] md:w-[227px]',
    'top-[120px] md:top-[142px] w-[238px] md:w-[227px]',
    'top-[120px] md:top-[142px] w-[238px] md:w-[227px]',
  ],
};

const CONFETTI_STEPS = {
  [GameType.SPORTS]: 4,
  [GameType.CASINO]: 3,
};

export const Game = ({
  showGameScreen,
  gameStarted,
  selectedGame,
  onAdvanceStep,
  currentStepData,
  activeStep,
}: Props) => {
  const [isStopped, setIsStopped] = useState(false);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    if (selectedGame === GameType.CASINO && activeStep === 2) {
      lottieRef.current?.play();
      setIsStopped(false);
      return;
    }

    lottieRef.current?.stop();
    setIsStopped(true);
  }, [activeStep, selectedGame]);

  useEffect(() => {
    if (activeStep === CONFETTI_STEPS[selectedGame] && gameStarted) {
      confetti();
    }
  }, [activeStep, gameStarted, selectedGame]);

  const handleAdvanceStep = () => {
    onAdvanceStep(activeStep + 1);
  };

  const handleAdvanceStepLottie = () => {
    if (!isStopped) {
      onAdvanceStep(activeStep + 1);
    }
  };

  const getOpacity = (step: Step) => {
    if (!step) return 0;
    if (!gameStarted) return 0;
    if (selectedGame === GameType.CASINO) return gameStarted && activeStep === step.key ? 1 : 0;
    if (activeStep === step.key || (activeStep >= step.key && activeStep < 4)) {
      return 1;
    }
    return 0;
  };

  const showSpinningLottie = gameStarted && showGameScreen && selectedGame === GameType.CASINO && activeStep !== 3;

  return (
    <>
      {showGameScreen && selectedGame === GameType.CASINO && (
        <AnimatedComponent
          initial={{ opacity: 0 }}
          animate={{ opacity: gameStarted ? 1 : 0, transition: { duration: 0.2 } }}
          className='bg-casino absolute z-10 left-0 right-0 mx-auto w-[220px] bottom-[110px] h-[200px]'
        />
      )}
      {showGameScreen &&
        currentStepData &&
        STEPS[selectedGame].map(step => (
          <AnimatedComponent
            key={step.key}
            initial={{ opacity: 0 }}
            animate={{
              opacity: getOpacity(step),
              transition: { duration: 0.2 },
            }}
            className={clsx('absolute z-20 left-0 right-0 mx-auto ', screenPositions[selectedGame][step.key - 1])}>
            <Image
              draggable={false}
              className={clsx('relative z-20', !gameStarted && 'pointer-events-none')}
              src={step.image}
              alt=' '
            />
            <div
              className={clsx(
                'absolute top-0 left-0 right-0 bottom-[20px]',
                selectedGame === GameType.SPORTS ? 'bg-primary-900' : 'bg-casino'
              )}
            />
          </AnimatedComponent>
        ))}

      <div className='group'>
        <GuideButton
          gameStarted={gameStarted}
          activeStep={activeStep}
          selectedGame={selectedGame}
          showGameScreen={showGameScreen}
          handleAdvanceStep={handleAdvanceStep}
        />
        <button
          type='button'
          onClick={handleAdvanceStep}
          aria-label='next step'
          className={clsx(
            'bg-primary-900 opacity-0 absolute cursor-pointer mx-auto focus:outline-none z-20 group-hover:opacity-25 md:hover:opacity-0 transition-opacity duration-200',
            advanceStepClasses[selectedGame][activeStep - 1],
            !gameStarted && 'pointer-events-none'
          )}
        />
      </div>

      <div
        className={clsx(
          'absolute md:scale-[0.9] z-30 top-[185px] md:top-[200px] left-[4px] right-0 mx-auto transition-opacity ease-in-out pointer-events-none duration-200 w-[290px]',
          showSpinningLottie ? 'opacity-100' : 'opacity-0'
        )}>
        <Lottie
          lottieRef={lottieRef}
          animationData={slotsSpinning}
          onComplete={handleAdvanceStepLottie}
          autoplay={false}
          loop={false}
        />
      </div>
    </>
  );
};
