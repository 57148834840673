import clsx from 'clsx';
import { Typography } from '@/components';

interface Props {
  children: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
  onPointerDown: (event: React.PointerEvent) => void;
  onPointerUp: (event: React.PointerEvent) => void;
  isSelected?: boolean;
  onClick?: () => void;
}

const Chip = ({
  children,
  className,
  onClick,
  onPointerDown,
  onPointerUp,
  isDisabled = false,
  isSelected = false,
}: Props) => (
  <div
    className={clsx(
      'rounded-lg bg-gradient-to-tr p-px inline-block',
      className,
      isDisabled ? 'from-neutral-300 to-neutral-300' : 'from-primary to-secondary'
    )}>
    <button
      type='button'
      disabled={isDisabled}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      className={clsx(
        'rounded-lg py-2 px-4 transition-all duration-200 ease-in-out bg-neutral-900 hover:bg-transparent md:hover:bg-neutral-900 text-neutral-0',
        isSelected && 'bg-transparent md:hover:bg-transparent text-neutral-0',
        isDisabled && 'bg-neutral-500 text-neutral-300'
      )}>
      <Typography size='bodySmall' tag='p'>
        {children}
      </Typography>
    </button>
  </div>
);

export default Chip;
