'use client';

import { useRef } from 'react';
import clsx from 'clsx';
import { AnimatedComponent, Typography } from '@/components';
import useScrollTriggeredCountUp from '@/hooks/useScrollTriggeredCountUp';

interface Props {
  children: React.ReactNode;
  title: string;
  className: string;
}

export const Feature = ({ children, title, className }: Props) => {
  const isCountUpTitle = title === '50+ Markets' || title === '16 Languages';
  const endNumber = title === '50+ Markets' ? 50 : 16;
  const ref = useRef<HTMLDivElement>(null);
  const count = useScrollTriggeredCountUp(ref, endNumber);

  const getTitle = () =>
    isCountUpTitle ? (
      <span ref={ref}>
        {count}
        {title.slice(2)}
      </span>
    ) : (
      title
    );

  return (
    <div
      className={clsx(
        'relative z-20 max-w-[528px] w-full h-[480px] lg:h-[450px] md:h-[372px] p-[2px] rounded-[52px] md:rounded-[32px] mx-auto',
        className
      )}>
      <div className='w-full h-full bg-neutral-900 rounded-[52px] md:rounded-[32px] pt-[54px] md:pt-[32px]'>
        <AnimatedComponent
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5, type: 'tween' } }}>
          <Typography className='text-center' tag='h3' size='2xl'>
            {getTitle()}
          </Typography>
        </AnimatedComponent>

        {children}
      </div>
    </div>
  );
};
