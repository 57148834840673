import { SVGProps } from '@/interfaces/SVGProps';

const Content = ({ className, width = 24, height = 24 }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    className={className}>
    <path d='M19.5 3.752h-15a2.25 2.25 0 0 0-2.25 2.25v10.5a2.25 2.25 0 0 0 2.25 2.25h6.75v1.5H9a.75.75 0 1 0 0 1.5h6a.75.75 0 0 0 0-1.5h-2.25v-1.5h6.75a2.25 2.25 0 0 0 2.25-2.25v-10.5a2.25 2.25 0 0 0-2.25-2.25Zm-15 1.5h15a.75.75 0 0 1 .75.75v7.5H3.75v-7.5a.75.75 0 0 1 .75-.75Zm15 12h-15a.75.75 0 0 1-.75-.75v-1.5h16.5v1.5a.75.75 0 0 1-.75.75Z' />
  </svg>
);
export default Content;
