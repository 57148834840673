import { MutableRefObject } from 'react';
import { Application } from '@splinetool/runtime';

interface Props {
  spline: MutableRefObject<Application | null>;
  variableName: string;
  startValue: number;
  endValue: number;
  duration: number;
}

const animateSplineVariable = ({ spline, variableName, startValue, endValue, duration }: Props) => {
  const startTime = Date.now();
  const changeInValue = endValue - startValue;

  const animate = () => {
    const elapsed = Date.now() - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const value = startValue + progress * changeInValue;

    if (spline.current) {
      spline.current.setVariable(variableName, value);
    }

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  };

  animate();
};

export default animateSplineVariable;
