import Image from 'next/image';
import { AnimatedComponent } from '@/components';
import { Feature } from '../Feature';
import { FeaturePhone3, DollarBadge, EuroBadge, Prize10000, Banner } from '@/assets';
import { CoinsLottie } from './CoinsLottie';

export const Markets = () => (
  <Feature className='bg-gradient-to-tr md:bg-gradient-to-r from-secondary to-primary-100' title='50+ Markets'>
    <div className='absolute top-0 right-0 left-0 bottom-[2px] w-full overflow-hidden'>
      <Image
        className='absolute bottom-0 mx-auto left-[45px] right-0 z-0 lg:w-[270px] lg:left-0 lg:-right-[43px] lg:mx-auto md:w-[234px] md:-left-[10px]'
        width={290}
        src={FeaturePhone3}
        alt='Phone'
      />
      <div className='absolute left-0 right-0 md:right-[150px] mx-auto top-[80px] md:top-[20px] md:scale-[0.7] w-[400px]'>
        <CoinsLottie />
      </div>
    </div>
    <AnimatedComponent
      className='absolute top-[100px] right-[30px] lg:w-[100px] lg:right-auto lg:top-[120px] md:top-[70px] lg:left-[130px] md:left-[35px]'
      initial={{ opacity: 0, translateY: -20 }}
      whileInView={{ opacity: 1, translateY: 0, transition: { duration: 0.8, type: 'spring' } }}
      animate={{
        y: [0, -7, 0],
        transition: {
          duration: 5,
          repeat: Infinity,
          delay: 1,
        },
      }}>
      <Image width={121} src={DollarBadge} alt='Dollar' />
    </AnimatedComponent>
    <AnimatedComponent
      className='absolute top-[180px] left-[30px] lg:w-[100px] lg:top-[150px] md:top-[180px] lg:left-[10px] md:left-auto md:right-0'
      initial={{ opacity: 0, translateY: -20 }}
      whileInView={{ opacity: 1, translateY: 0, transition: { duration: 0.8, type: 'spring' } }}
      animate={{
        y: [0, -7, 0],
        transition: {
          duration: 7,
          repeat: Infinity,
          delay: 0,
        },
      }}>
      <Image width={121} src={EuroBadge} alt='Euro' />
    </AnimatedComponent>
    <AnimatedComponent
      className='absolute top-[190px] right-[60px] lg:top-auto lg:bottom-[170px] md:right-[20px] md:bottom-[190px] md:w-[120px]'
      initial={{ opacity: 0, scale: 0.8, x: -20 }}
      whileInView={{ opacity: 1, scale: 1, x: 0, transition: { duration: 0.8, type: 'spring', delay: 0.2 } }}
      animate={{
        y: [0, 12, 0],
        transition: {
          duration: 4,
          repeat: Infinity,
          delay: 1,
        },
      }}>
      <Image width={160} src={Prize10000} alt='$10000' />
    </AnimatedComponent>
    <AnimatedComponent
      className='shadow-xl absolute bottom-[40px] -left-[30px] lg:w-[205px] lg:-left-[40px] md:bottom-[40px] md:-left-[15px]'
      initial={{ opacity: 0, scale: 0.8, x: 20 }}
      whileInView={{ opacity: 1, scale: 1, x: 0, transition: { duration: 0.8, type: 'spring', delay: 0.3 } }}
      animate={{
        y: [0, 8, 0],
        transition: {
          duration: 6,
          repeat: Infinity,
          delay: 1,
        },
      }}>
      <Image width={287} src={Banner} alt='Prize Banner' />
    </AnimatedComponent>
  </Feature>
);
