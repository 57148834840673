import clsx from 'clsx';
import { FontWeight } from '@/types/FontWeight';

interface Props {
  tag: Tag;
  children: React.ReactNode;
  size: Size;
  weight?: FontWeight;
  className?: string;
}

type Tag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
export type Size =
  | '6xl'
  | '5xl'
  | '4xl'
  | '3xl'
  | '2xl'
  | '1xl'
  | 'bodyLarge'
  | 'bodyMedium'
  | 'bodySmall'
  | 'bodyExtraSmall';

const Typography = ({ tag, children, className, size, weight = 'normal' }: Props) => {
  const Tag = tag;

  const sizes = {
    '6xl': 'text-6xl md:text-6xl-mobile',
    '5xl': 'text-5xl md:text-5xl-mobile',
    '4xl': 'text-4xl md:text-4xl-mobile',
    '3xl': 'text-3xl md:text-3xl-mobile',
    '2xl': 'text-2xl md:text-2xl-mobile',
    '1xl': 'text-1xl md:text-1xl-mobile',
    bodyLarge: 'text-body-lg',
    bodyMedium: 'text-body-md',
    bodySmall: 'text-body-sm',
    bodyExtraSmall: 'text-body-xs',
  };

  return <Tag className={clsx(className, sizes[size], weight && `font-${weight}`)}>{children}</Tag>;
};

export default Typography;
