'use client';

import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Application } from '@splinetool/runtime';
import { AnimatedComponent, Typography } from '@/components';
import { Heading } from './Heading';
import { Description } from './Description';
import { SplineCanvas } from './SplineCanvas';
import useIsMobile from '@/hooks/useIsMobile';
import { HeadingKey } from './utils';
import animateSplineVariable from '@/utils/animateSplineVariable';

const ANIMATION_DURATION = 300;
const MOBILE_X_POSITION = 270;
const DESKTOP_X_POSITION = 460;
const MAX_VARIABLE_VALUE = 100;

const HEADINGS: Record<HeadingKey, { key: HeadingKey; description: string }> = {
  expertise: {
    key: 'expertise',
    description: 'It is easy(ish) to build free-to-play games. It is not easy to know why, when and how to use them.',
  },
  data: {
    key: 'data',
    description: 'Data sits at the heart of everything we do. Test & Learn until ROI and beyond.',
  },
  content: {
    key: 'content',
    description:
      'Content is King & Queen. From Big Brother Brazil to Mr. Olympia, the Rugby World Cup to CS2 and everything in between. Relevant content means an engaged audience.',
  },
};

const WorkWithUs = () => {
  const [selectedHeading, setSelectedHeading] = useState<HeadingKey>(HEADINGS.expertise.key);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const isMobile = useIsMobile();

  const spline = useRef<Application | null>(null);

  useEffect(() => {
    if (!spline.current) {
      return;
    }

    const xPosition = isMobile ? MOBILE_X_POSITION : DESKTOP_X_POSITION;
    spline.current.setVariable('xPosition', xPosition);
  }, [isDescriptionVisible, isMobile]);

  const handleClickHeading = (id: HeadingKey) => {
    setIsDescriptionVisible(true);
    setSelectedHeading(id);

    if (spline.current) {
      spline.current.emitEvent('mouseDown', '4ce6f758-5490-4063-9428-843148f933f6');
    }

    const variables = {
      data: 0,
      expertise: 0,
      content: 0,
    };

    variables[id] = MAX_VARIABLE_VALUE;

    Object.entries(variables).forEach(([key, value]) => {
      if (spline.current) {
        animateSplineVariable({
          spline,
          variableName: key,
          startValue: Number(spline.current.getVariable(key)),
          endValue: value,
          duration: ANIMATION_DURATION,
        });
      }
    });
  };

  const handleCloseDescription = () => {
    setIsDescriptionVisible(false);

    if (spline.current) {
      spline.current.emitEventReverse('mouseDown', '4ce6f758-5490-4063-9428-843148f933f6');

      const variables = {
        data: MAX_VARIABLE_VALUE,
        expertise: 0,
        content: 0,
      };

      Object.entries(variables).forEach(([key, endValue]) => {
        if (spline.current) {
          animateSplineVariable({
            spline,
            variableName: key,
            startValue: Number(spline.current.getVariable(key)),
            endValue,
            duration: ANIMATION_DURATION,
          });
        }
      });
    }
  };

  const headingPosition = isDescriptionVisible ? 'ml-0 translate-x-0' : 'ml-[50%] translate-x-[-50%]';
  const getHeadingOpacity = (id: HeadingKey) =>
    isDescriptionVisible && selectedHeading !== id ? 'opacity-20' : 'opacity-100';

  return (
    <section className='relative text-neutral-0 px-[110px] md:px-6 overflow-hidden' id='work'>
      <div className={clsx('absolute inset-0 flex justify-center items-center top-[10px] md:-top-[50px]')}>
        <SplineCanvas spline={spline} />
      </div>
      <div>
        <Description
          selectedHeading={selectedHeading}
          selectedDescription={HEADINGS[selectedHeading].description}
          isDescriptionVisible={isDescriptionVisible}
          handleCloseDescription={handleCloseDescription}
        />
        <AnimatedComponent
          initial={{ opacity: 0, y: 40 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, type: 'spring' },
          }}>
          <Typography tag='h2' size='2xl' className='mb-[35px] text-center w-full'>
            Why work with Splash?
          </Typography>
        </AnimatedComponent>

        <div className={clsx('w-[563px] md:w-[311px] transition-all duration-500 ease-in-out z-0', headingPosition)}>
          <Heading
            id={HEADINGS.expertise.key}
            className={getHeadingOpacity(HEADINGS.expertise.key)}
            isDescriptionVisible={isDescriptionVisible}
            buttonClassName='right-[68px] md:right-[20px] transition-opacity duration-500 ease-in-out'
            onClick={handleClickHeading}>
            {HEADINGS.expertise.key}
          </Heading>
        </div>
        <div
          className={`w-[280px] md:w-[155px] relative z-10 block transition-all duration-500 ease-in-out ${headingPosition}`}>
          <Heading
            id={HEADINGS.data.key}
            className={getHeadingOpacity(HEADINGS.data.key)}
            isDescriptionVisible={isDescriptionVisible}
            buttonClassName='left-0 md:-left-[15px] transition-opacity duration-500 ease-in-out'
            onClick={handleClickHeading}>
            {HEADINGS.data.key}
          </Heading>
        </div>
        <div className={clsx('w-[494px] md:w-[273px] transition-all duration-500 ease-in-out z-0', headingPosition)}>
          <Heading
            id={HEADINGS.content.key}
            className={getHeadingOpacity(HEADINGS.content.key)}
            isDescriptionVisible={isDescriptionVisible}
            buttonClassName='right-[290px] md:right-[160px] transition-opacity duration-500 ease-in-out'
            onClick={handleClickHeading}>
            {HEADINGS.content.key}
          </Heading>
        </div>
      </div>
    </section>
  );
};

export default WorkWithUs;
