import Image from 'next/image';
import { AnimatedComponent } from '@/components';
import { Feature } from './Feature';
import { FeaturePhone1, MedalBadge, ESL, G2, Tennis, AmericanFootball } from '@/assets';

export const Sports = () => (
  <Feature className='bg-gradient-to-r from-secondary to-primary-200' title='Every Sport & League'>
    <div className='absolute top-0 right-0 left-0 bottom-[2px] w-full overflow-hidden'>
      <Image
        className='absolute -bottom-[50px] lg:bottom-0 left-0 right-0 mx-auto w-full lg:max-w-[430px] md:max-w-[390px] 2xs:max-w-[310px]'
        src={FeaturePhone1}
        alt='Phone'
      />
    </div>
    <AnimatedComponent
      className='absolute bottom-[60px] md:bottom-[80px] right-[80px] md:right-[26px] md:w-[100px] z-0'
      initial={{ opacity: 0, translateY: -20 }}
      whileInView={{ opacity: 1, translateY: 0, transition: { duration: 0.8, type: 'spring' } }}
      animate={{
        y: [0, 8, 0],
        transition: {
          duration: 5,
          repeat: Infinity,
          type: 'tween',
        },
      }}>
      <Image width={111} src={MedalBadge} alt='Medal' />
    </AnimatedComponent>

    <AnimatedComponent
      className='absolute bottom-[54px] -left-[36px] xl:left-[30px] lg:-left-[15px] w-[242px] lg:w-[196px] bg-neutral-0-transparent-40 z-20 backdrop-blur-[6px] rounded-[10px] flex items-center p-[17px]'
      initial={{ opacity: 0, x: 20 }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: { duration: 0.8, type: 'spring', delay: 0.2 },
      }}
      animate={{
        y: [0, 15, 0],
        transition: {
          duration: 7,
          repeat: Infinity,
        },
      }}>
      <Image className='' width={350} src={ESL} alt='ESL Pro League' />
    </AnimatedComponent>

    <div className='absolute -bottom-[25px] right-[50px] md:-right-4 flex z-20 md:scale-[0.8]'>
      <AnimatedComponent
        className='relative z-20'
        initial={{ opacity: 0, translateY: 20 }}
        whileInView={{ opacity: 1, translateY: 0, transition: { duration: 0.8, type: 'spring' } }}
        animate={{
          y: [0, 6, 0],
          transition: {
            duration: 4,
            repeat: Infinity,
            delay: 1,
          },
        }}>
        <Image width={64} src={G2} alt='G2 Esports' />
      </AnimatedComponent>
      <AnimatedComponent
        className='relative z-10 right-5'
        initial={{ opacity: 0, translateY: 20 }}
        whileInView={{ opacity: 1, translateY: 0, transition: { duration: 0.8, type: 'spring', delay: 0.1 } }}
        animate={{
          y: [0, 6, 0],
          transition: {
            duration: 4,
            repeat: Infinity,
            delay: 1,
          },
        }}>
        <Image width={64} src={Tennis} alt='Tennis ball' />
      </AnimatedComponent>
      <AnimatedComponent
        className='relative z-0 right-10'
        initial={{ opacity: 0, translateY: 20 }}
        whileInView={{ opacity: 1, translateY: 0, transition: { duration: 0.8, type: 'spring', delay: 0.3 } }}
        animate={{
          y: [0, 6, 0],
          transition: {
            duration: 4,
            repeat: Infinity,
            delay: 1,
          },
        }}>
        <Image width={64} src={AmericanFootball} alt='American football' />
      </AnimatedComponent>
    </div>
  </Feature>
);
