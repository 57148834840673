import Image from 'next/image';
import { Typography } from '@/components';
import { Phones } from './Phones';
import {
  HeroBackgroundMobile,
  HeroBackgroundGradientXL,
  HeroBackgroundGradientDesktop,
  HeroBackgroundMisc1,
  HeroBackgroundMisc2,
  HeroBackgroundCoins1,
  HeroBackgroundCoins2,
  HeroBackgroundCoinsMobile,
  HeroBackgroundMiscMobile,
} from '@/assets';

const Hero = () => {
  return (
    <section className='relative w-full h-full pt-[165px] md:pt-[130px] flex flex-col items-center pb-[240px] md:pb-[160px] overflow-visible'>
      <Image
        className='absolute object-center object-cover mx-auto z-0 block 2xl:hidden'
        src={HeroBackgroundGradientXL}
        fill
        priority
        alt=' '
      />
      <Image
        className='absolute object-center object-cover mx-auto z-0 hidden 2xl:block md:hidden'
        src={HeroBackgroundGradientDesktop}
        fill
        priority
        alt=' '
      />
      <Image
        className='absolute object-center object-cover mx-auto z-0 w-full hidden md:block'
        src={HeroBackgroundMobile}
        fill
        priority
        alt=' '
      />
      <Image
        className='absolute mx-auto z-10 w-full hidden md:block mix-blend-overlay top-[350px] opacity-80'
        src={HeroBackgroundMiscMobile}
        priority
        alt=' '
      />
      <Image
        className='absolute z-10 top-[280px] left-[550px] 3xl:left-[350px] 2xl:left-[150px] xl:left-[50px] md:hidden mix-blend-overlay'
        src={HeroBackgroundMisc1}
        priority
        alt=' '
      />
      <Image
        className='absolute z-10 top-[280px] right-[620px] 3xl:right-[300px] 2xl:right-[220px] xl:right-[50px] md:hidden mix-blend-overlay'
        src={HeroBackgroundMisc2}
        priority
        alt=' '
      />
      <Image
        className='absolute z-20 top-[340px] left-[750px] 3xl:left-[350px] 2xl:left-[250px] xl:left-[220px] md:hidden mix-blend-difference'
        src={HeroBackgroundCoins1}
        priority
        alt=' '
      />
      <Image
        className='absolute z-20 top-[390px] md:top-[320px] right-[820px] 3xl:right-[300px] 2xl:right-[170px] xl:right-[150px] md:hidden mix-blend-difference'
        src={HeroBackgroundCoins2}
        priority
        alt=' '
      />
      <Image
        className='absolute z-20 hidden md:block top-[280px] xs:top-[340px] w-full max-w-[500px] left-0 right-0 mx-auto mix-blend-difference'
        src={HeroBackgroundCoinsMobile}
        priority
        alt=' '
      />

      <div className='text-neutral-0 relative max-w-[800px] md:max-w-[340px] text-center'>
        <Typography tag='h1' size='3xl' className='md:text-4xl-mobile'>
          We build games that are used to{' '}
          <span className='block md:inline bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary'>
            <span className='md:text-neutral-0'>acquire</span> and retain customers
          </span>
        </Typography>
      </div>
      <div className='w-full z-50 h-[600px] relative top-[20px] md:top-[0] flex flex-col items-center overflow-x-clip'>
        <Phones />
      </div>
    </section>
  );
};

export default Hero;
