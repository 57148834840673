import { Link } from '@/components';

interface Props {
  href: string;
  label: string;
  onClick?: () => void;
}

export const NavItem = ({ href, label, onClick }: Props) => {
  return (
    <li className='flex items-center'>
      <Link
        onClick={onClick}
        href={href}
        weight='medium'
        size='bodySmall'
        textClassName='lg:text-body-xs md:text-body-md'>
        {label}
      </Link>
    </li>
  );
};
