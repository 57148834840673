import { SVGProps } from '@/interfaces/SVGProps';

const Hamburguer = ({ className, width = 32, height = 32 }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 32 32'
    className={className}>
    <path d='M28 16a1 1 0 0 1-1 1H5a1 1 0 0 1 0-2h22a1 1 0 0 1 1 1ZM5 9h22a1 1 0 1 0 0-2H5a1 1 0 0 0 0 2Zm22 14H5a1 1 0 0 0 0 2h22a1 1 0 0 0 0-2Z' />
  </svg>
);
export default Hamburguer;
