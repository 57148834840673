export { default as Button } from './common/Button';
export { default as Icon } from './common/Icon';
export { default as Typography } from './common/Typography';
export { default as Link } from './common/Link';
export { default as Chip } from './common/Chip';
export { default as Line } from './common/Line';
export { default as AnimatedComponent } from './common/AnimatedComponent';

export { default as NavbarDesktop } from './Navbar/NavbarDesktop';
export { default as NavbarMobile } from './Navbar/NavbarMobile';

export { default as Hero } from './Hero/Hero';
export { default as Partners } from './Partners/Partners';
export { default as WorkWithUs } from './WorkWithUs/WorkWithUs';
export { default as Lobby } from './Lobby/Lobby';
export { default as Features } from './Features/Features';
export { default as Quotes } from './Quotes/Quotes';
export { default as Team } from './Team/Team';
export { default as Footer } from './Footer/Footer';
export { default as Loader } from './Loader/Loader';

export { default as Cursor } from './Cursor/Cursor';
