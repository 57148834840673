import { SVGProps } from '@/interfaces/SVGProps';

const ChevronDown = ({ className, width = 24, height = 24 }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    className={className}
    viewBox='0 0 17 17'>
    <path d='m13.854 6.854-5 5a.5.5 0 0 1-.707 0l-5-5a.5.5 0 1 1 .707-.708L8.5 10.793l4.646-4.647a.5.5 0 0 1 .708.708Z' />
  </svg>
);
export default ChevronDown;
