import Image from 'next/image';
import { AnimatedComponent, Typography } from '@/components';
import { TrophyBadge, StarBadge, BrandNewBadge, LobbyPhone, LobbyPhone2, LobbyBackground } from '@/assets';

const Lobby = () => {
  return (
    <section id='lobby' className='px-[160px] md:px-[24px] mt-[260px] lg:mt-[150px] mb-[350px] lg:mb-[254px]'>
      <div
        className='relative bg-gradient-to-tr from-primary to-secondary rounded-[52px] md:rounded-[40px]
      max-w-[1260px] mx-auto p-[2px] h-[544px] lg:h-[800px] md:h-[633px] text-neutral-0 overflow-visible'>
        <div className='relative rounded-[52px] md:rounded-[40px] bg-neutral-900 w-full h-full overflow-hidden px-[99px] md:px-[10px] pt-8 flex items-end lg:items-start lg:justify-center'>
          <Image
            className='absolute bottom-0 right-0 md:right-auto left-0 md:-left-[150px] min-w-[1181px] z-0'
            src={LobbyBackground}
            alt=' '
          />
          <Image
            className='absolute bottom-[40px] -right-[80px] z-10 xl:hidden animate-spin-slow origin-[51%_50%]'
            src={BrandNewBadge}
            alt='Brand new'
          />
          <div className='mb-[140px] lg:mt-[93px] md:mt-[70px] lg:text-center relative z-20'>
            <Typography className='relative z-10 max-w-[373px]' tag='h2' size='2xl'>
              Meet us in our
              <span className='block text-[120px] leading-[144px] xl:text-4xl lg:text-5xl-mobile xl:mb-2'>Lobby</span>
            </Typography>
            <Typography className='max-w-[252px] md:max-w-[191px] md:text-body-xs' tag='p' size='bodyMedium'>
              Your hub for hosting multiple games all under one roof.
            </Typography>
          </div>
        </div>
        <div className='w-[191px] absolute top-[90px] lg:top-4 -left-8 lg:left-0 lg:-right-0 lg:mx-auto z-20'>
          <div className='absolute top-[40px] lg:top-[20px] w-[60px] lg:w-[48px] left-6 lg:-left-[20px] z-20'>
            <Image src={TrophyBadge} alt='Trophy' />
          </div>
          <div className='absolute md:w-[180px] -top-[110px] lg:-top-[110px] md:-top-[95px] w-[220px] lg:w-[203px] left-14 lg:left-10 animate-spin-slow rotate-180 origin-[50%_48%]'>
            <Image src={StarBadge} alt='Star' />
          </div>
          <Typography
            className='relative z-10 rounded-full bg-primary py-4 lg:py-[12px] px-[44px] lg:px-[28px] w-max lg:text-body-sm'
            tag='p'
            size='bodyMedium'>
            Brand new feature
          </Typography>
        </div>
        <div className='absolute top-8 lg:top-[320px] md:top-[270px] right-[240px] xl:right-[20px] lg:right-0 lg:-left-[60px] mx-auto z-20 lg:w-[324px] md:w-[239px] md:-left-[45px]'>
          <AnimatedComponent
            className='inline-block absolute top-[160px] md:top-[130px] -left-[10px] z-20 rounded-[8px] py-3 px-4 bg-neutral-0-transparent-40 backdrop-blur-[10px] shadow-xl'
            initial={{ opacity: 0, y: -20, scale: 0.9 }}
            whileInView={{ opacity: 1, y: 0, scale: 1, transition: { duration: 0.8, type: 'spring' } }}>
            <Typography tag='p' size='bodyExtraSmall'>
              Banner section
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent
            className='inline-block absolute top-[270px] md:top-[210px] -right-[20px] z-20 rounded-[8px] py-3 px-4 bg-neutral-0-transparent-40 backdrop-blur-[10px] shadow-xl md:-right-[20px]'
            initial={{ opacity: 0, y: -20, scale: 0.9 }}
            whileInView={{ opacity: 1, y: 0, scale: 1, transition: { duration: 0.8, type: 'spring', delay: 0.2 } }}>
            <Typography tag='p' size='bodyExtraSmall'>
              Recent winners
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent
            className='inline-block absolute top-[430px] md:top-[350px] z-20 rounded-[8px] py-3 px-4 bg-neutral-0-transparent-40 backdrop-blur-[10px] shadow-xl'
            initial={{ opacity: 0, y: -20, scale: 0.9 }}
            whileInView={{ opacity: 1, y: 5, scale: 1, transition: { duration: 0.8, type: 'spring', delay: 0.3 } }}>
            <Typography tag='p' size='bodyExtraSmall'>
              Games catalogue
            </Typography>
          </AnimatedComponent>
          <Image width={324} height={639} src={LobbyPhone} alt='Phone' />
        </div>

        <Image
          className='absolute bottom-[2px] right-[60px] xl:right-[90px] lg:w-[260px] lg:right-[40px] md:w-[204px] md:right-[25px]'
          width={306}
          height={630}
          src={LobbyPhone2}
          alt='Phone'
        />
      </div>
    </section>
  );
};

export default Lobby;
