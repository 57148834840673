import { SVGProps } from '@/interfaces/SVGProps';

interface Props extends SVGProps {
  Component: ({ className, width, height }: SVGProps) => JSX.Element;
  className?: string;
}

const Icon = ({ Component, width, height, className }: Props) => {
  return (
    <span>
      <Component width={width} height={height} className={className} />
    </span>
  );
};

export default Icon;
