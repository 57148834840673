import clsx from 'clsx';
import { AnimatedComponent, Button, Icon, Typography } from '@/components';
import { HeadingKey } from './utils';
import { Plus } from '@/assets';

interface Props {
  children: 'expertise' | 'data' | 'content';
  id: HeadingKey;
  className: string;
  buttonClassName: string;
  isDescriptionVisible: boolean;
  onClick: (id: HeadingKey) => void;
}

const WORD_DELAY = {
  expertise: 0,
  data: 0.1,
  content: 0.1,
};
const LETTER_SPEED = 0.3;

export const Heading = ({ children, id, className, buttonClassName, isDescriptionVisible, onClick }: Props) => (
  <div className='relative mb-[64px] md:mb-[101px]'>
    <Typography
      tag='h6'
      size='5xl'
      className={clsx(
        `text-[120px] capitalize md:text-6xl-mobile bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary opacity-25 absolute top-0 left-0`,
        className
      )}>
      {children}
    </Typography>
    <Typography
      tag='h6'
      size='5xl'
      className={clsx(
        `text-[120px] md:text-6xl-mobile bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary 
        transition-opacity duration-300 ease-in-out`,
        className
      )}>
      {children.split('').map((letter, i) => (
        <AnimatedComponent
          tag='span'
          className={i === 0 ? 'capitalize' : ''}
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: {
              duration: 0.2,
              delay: WORD_DELAY[children] + (i / children.length) ** 3 * LETTER_SPEED, // Aquí se ajusta la fórmula de delay
            },
          }}
          viewportMargin='-60px 0% -100px 0%'
          // eslint-disable-next-line react/no-array-index-key
          key={i}>
          {letter}
        </AnimatedComponent>
      ))}
    </Typography>

    <AnimatedComponent
      initial={{ opacity: 0 }}
      viewportMargin='-20px 0% -30px 0%'
      whileInView={{
        opacity: 1,
        transition: { delay: 0.8, duration: 0.2 },
      }}
      className={clsx('absolute z-20 bottom-[14px] md:-bottom-[8px]', buttonClassName)}>
      <Button
        className={clsx(
          'transition-opacity duration-200',
          isDescriptionVisible ? 'pointer-events-none opacity-0' : 'opacity-100'
        )}
        onClick={() => onClick(id)}
        variant='icon'
        size='small'>
        <Icon className='fill-neutral-0' width={20} height={20} Component={Plus} />
      </Button>
    </AnimatedComponent>
  </div>
);
