import Slider from 'react-slick';
import { Chip } from '@/components';
import { Filter, FilterKey } from '@/types/Filters';

interface Props {
  filters: Filter[];
  selectedFilters: FilterKey[];
  onFilterChange: (filter: FilterKey) => void;
}

const settings = {
  speed: 500,
  swipeToSlide: false,
  variableWidth: true,
  arrows: false,
  touchThreshold: 100,
  infinite: false,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        infinite: true,
        swipeToSlide: true,
      },
    },
  ],
};

export const renderItems = (
  filters: Filter[],
  onFilterChange: (filter: FilterKey) => void,
  selectedFilters: FilterKey[]
): JSX.Element[] => {
  let startX = 0;
  let endX = 0;

  const handleStart = (event: React.PointerEvent) => {
    startX = event.clientX;
    event.stopPropagation();
  };

  const handleEnd = (event: React.PointerEvent, key: FilterKey) => {
    endX = event.clientX;
    if (Math.abs(startX - endX) < 10) {
      onFilterChange(key);
    }
    event.stopPropagation();
  };

  return filters.map(({ key, label }) => (
    <Chip
      className='mr-4'
      key={key}
      onPointerDown={handleStart}
      onPointerUp={event => handleEnd(event, key)}
      isSelected={selectedFilters.includes(key)}>
      {label}
    </Chip>
  ));
};

export const Swiper = ({ filters, onFilterChange, selectedFilters }: Props) => (
  <>
    <div className='mt-[104px] flex gap-6 flex-wrap md:flex-nowrap justify-center md:min-w-[890px] md:gap-4 md:hidden [&>div]:mr-0'>
      {renderItems(filters, onFilterChange, selectedFilters)}
    </div>
    <div className='hidden  md:block'>
      <Slider {...settings} className='mt-[64px] w-screen md:min-w-[890px] [&>div]:overflow-visible'>
        {renderItems(filters, onFilterChange, selectedFilters)}
      </Slider>
    </div>
  </>
);
