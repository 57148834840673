import { Typography } from '@/components';

interface Props {
  text: string;
  author: string;
  role: string;
  partner: string;
}

export const Card = ({ text, author, role, partner }: Props) => (
  <div className='w-[352px] md:w-[327px] h-[366px] md:h-[334px] rounded-[52px] md:rounded-[32px] p-[40px] md:p-[32px] bg-neutral-900 flex flex-col justify-between mx-auto'>
    <Typography tag='p' size='bodyMedium'>
      &quot;{text}&quot;
    </Typography>
    <div className='flex flex-col justify-start'>
      <Typography tag='p' size='bodySmall' weight='bold'>
        {author}
      </Typography>
      <Typography className='text-neutral-500 mt-[4px]' tag='p' size='bodySmall'>
        {role}, {partner}
      </Typography>
    </div>
  </div>
);
