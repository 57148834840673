import Image from 'next/image';
import { Typography } from '@/components';
import { Swiper } from './Swiper';
import { TeamBackground, TeamGrid } from '@/assets';

const Team = () => (
  <section
    className='relative pt-[114px] pb-[326px] md:pb-[197px] overflow-visible xl:overflow-hidden border-b-neutral-0 border-b-[1px]'
    id='team'>
    <Image
      className='object-center 2xl:object-cover mx-auto absolute z-0 md:inset-[0 -200px]'
      src={TeamBackground}
      fill
      alt=' '
    />
    <Image
      className='object-center 2xl:object-cover mx-auto absolute z-10 md:inset-[0 -200px] mix-blend-overlay'
      src={TeamGrid}
      fill
      alt=' '
    />

    <div className='relative z-20'>
      <Typography className='relative z-10 text-neutral-0 text-center' tag='h2' size='2xl'>
        Our Team
      </Typography>
      <div className='w-full overflow-hidden'>
        <Swiper />
      </div>
    </div>
  </section>
);

export default Team;
