import { SportsStep1, SportsStep2, SportsStep3, SportsStep4, CasinoStep1, CasinoStep2, CasinoStep3 } from '@/assets';

export enum GameType {
  SPORTS = 'SPORTS',
  CASINO = 'CASINO',
}

export const STEPS = {
  SPORTS: [
    {
      key: 1,
      image: SportsStep1,
    },
    {
      key: 2,
      image: SportsStep2,
    },
    {
      key: 3,
      image: SportsStep3,
    },
    {
      key: 4,
      image: SportsStep4,
    },
  ],
  CASINO: [
    {
      key: 1,
      image: CasinoStep1,
    },
    {
      key: 2,
      image: CasinoStep2,
    },
    {
      key: 3,
      image: CasinoStep3,
    },
  ],
};
