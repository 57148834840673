import { Line } from '@/components';

const LINES: {
  direction: 'horizontal' | 'vertical';
  className: string;
}[] = [
  {
    direction: 'horizontal',
    className: 'absolute top-0 left-0 right-0 opacity-75 md:hidden',
  },
  {
    direction: 'vertical',
    className: 'absolute top-0 left-[380px] lg:left-[150px] opacity-15 z-0 md:hidden h-[980px] md:h-[580px]',
  },
  {
    direction: 'vertical',
    className:
      'absolute top-0 left-[436px] lg:left-[206px] opacity-15 md:opacity-25 z-0 md:left-[62px] h-[980px] md:h-[580px]',
  },
  {
    direction: 'vertical',
    className:
      'absolute -top-[30px] right-[370px] lg:right-[150px] opacity-15 z-0 md:right-[62px] h-[980px] md:h-[580px]',
  },
  {
    direction: 'vertical',
    className: 'absolute -top-[70px] right-[426px] lg:right-[206px] opacity-15 z-0 md:hidden h-[980px] md:h-[580px]',
  },
];

export const LinesDecorations = () => (
  <>
    {LINES.map(({ className, direction }) => (
      <Line
        key={className}
        direction={direction}
        className={className}
        gradient='from-transparent via-[#BAD7F759] to-transparent'
      />
    ))}
  </>
);
