import { useState, useEffect } from 'react';

const useScrolled = () => {
  const initialScrollPosition = typeof window !== 'undefined' ? window.scrollY : 0;
  const [lastScrollTop, setLastScrollTop] = useState(initialScrollPosition);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(initialScrollPosition > 0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      setIsScrolled(scrollTop > 0);
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);

      if (scrollTop > lastScrollTop) {
        setIsScrollingDown(true);
      } else {
        setIsScrollingDown(false);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  return { isScrollingDown, isScrolled };
};

export default useScrolled;
