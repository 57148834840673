import NextLink from 'next/link';
import clsx from 'clsx';
import { Typography } from '@/components';
import { Size } from './Typography';
import { FontWeight } from '@/types/FontWeight';

interface Props {
  children: React.ReactNode;
  href: string;
  size?: Size;
  weight?: FontWeight;
  isDisabled?: boolean;
  textClassName?: string;
  onClick?: () => void;
}

const Link = ({
  children,
  href,
  textClassName,
  onClick,
  size = 'bodySmall',
  weight = 'normal',
  isDisabled = false,
}: Props) => {
  return (
    <NextLink
      onClick={onClick}
      href={href}
      className={`inline-block relative group overflow-hidden ${isDisabled && 'pointer-events-none'}`}>
      <Typography
        tag='p'
        size={size}
        weight={weight}
        className={clsx('text-neutral-0 inline', textClassName, {
          'pointer-events-none text-neutral-500': isDisabled,
        })}>
        {children}
      </Typography>
      <div className='absolute bottom-0 -left-full w-full h-0.5 bg-gradient-to-tr from-primary to-secondary group-hover:left-0 transition-left duration-200 ease-in-out' />
    </NextLink>
  );
};

export default Link;
