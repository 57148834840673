import clsx from 'clsx';
import { AnimatedComponent } from '@/components';
import { GameType } from './utils';

const guidePositions = {
  [GameType.SPORTS]: [
    'bottom-[137px] left-[110px]',
    'bottom-[230px] -left-[129px] md:bottom-[210px]',
    'bottom-[120px] -left-[79px] md:bottom-[110px]',
    'bottom-[235px] md:bottom-[220px] left-[80px] md:left-[90px]',
  ],
  [GameType.CASINO]: ['bottom-[187px] left-[110px]', 'hidden', 'bottom-[155px] left-[90px]'],
};

interface Props {
  activeStep: number;
  showGameScreen: boolean;
  gameStarted: boolean;
  selectedGame: GameType;
  handleAdvanceStep: () => void;
}

export const GuideButton = ({ selectedGame, activeStep, handleAdvanceStep, showGameScreen, gameStarted }: Props) => (
  <button type='button' onClick={handleAdvanceStep} aria-label='Next step'>
    <AnimatedComponent
      animate={{ translateY: [-15, 15, -15], transition: { duration: 2, repeat: Infinity } }}
      className={clsx(
        'absolute transition-all duration-500 cursor-pointer ease-in-out right-0 z-40 mx-auto flex items-center justify-center w-[36px] h-[36px] bg-secondary-transparent-20 rounded-full backdrop-blur-lg focus:outline-none',
        guidePositions[selectedGame][activeStep - 1],
        showGameScreen && gameStarted ? 'opacity-100' : 'opacity-0'
      )}>
      <AnimatedComponent
        animate={{ scale: [0.9, 1.2, 0.9], transition: { duration: 1, repeat: Infinity } }}
        className='w-[12px] h-[12px] z-30'>
        <div className='bg-secondary w-[12px] h-[12px] rounded-full' />
      </AnimatedComponent>
    </AnimatedComponent>
  </button>
);
