import { SVGProps } from '@/interfaces/SVGProps';

const Data = ({ className, width = 24, height = 24 }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    className={className}>
    <path d='M9 3.75V7.5H5.25a.75.75 0 0 0-.75.75V19.5h-.75a.75.75 0 1 0 0 1.5h16.5a.75.75 0 1 0 0-1.5h-.75v-8.25a.75.75 0 0 0-.75-.75H15V3.75a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75ZM6 9h3v10.5H6V9Zm12 3v7.5h-3V12h3Zm-4.5-7.5v15h-3v-15h3Z' />
  </svg>
);
export default Data;
