'use client';

import { useEffect, useState } from 'react';

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const mousemove = (e: MouseEvent) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', mousemove);

    return () => {
      window.removeEventListener('mousemove', mousemove);
    };
  }, []);

  return (
    <div
      style={{
        transform: `translate(${position.x - 32}px, ${position.y - 32}px)`,
      }}
      className='md:hidden z-50 w-0 h-0 rounded-full fixed top-0 left-0 pointer-events-none shadow-cursor overflow-visible text-neutral-0'
    />
  );
};

export default Cursor;
