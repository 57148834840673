'use client';

import Image from 'next/image';
import { motion } from 'framer-motion';
import { Sticker1, Sticker2, Sticker3, Sticker4, Sticker5, Sticker6, Sticker7, Sticker8 } from '@/assets';

const STICKERS = [
  {
    key: 'sticker1',
    src: Sticker1,
    className: 'right-[190px] z-10',
  },
  {
    key: 'sticker2',
    src: Sticker2,
    className: 'right-[130px]',
  },
  {
    key: 'sticker3',
    src: Sticker3,
    className: 'right-[250px] md:right-[230px]',
  },
  {
    key: 'sticker4',
    src: Sticker4,
    className: 'right-[450px] md:right-[260px]',
  },
  {
    key: 'sticker5',
    src: Sticker5,
    className: 'right-[350px] md:right-[270px]',
  },
  {
    key: 'sticker6',
    src: Sticker6,
    className: 'right-[450px] md:right-[50px]',
  },
  {
    key: 'sticker7',
    src: Sticker7,
    className: 'right-[400px] md:right-[150px]',
  },
  {
    key: 'sticker8',
    src: Sticker4,
    className: 'right-[150px] md:right-[120px]',
  },
  {
    key: 'sticker9',
    src: Sticker8,
    className: 'right-[400px] md:right-[220px]',
  },
  {
    key: 'sticker10',
    src: Sticker8,
    className: 'right-[100px] md:right-0',
  },
];

export const Stickers = () => {
  return (
    <div className='absolute top-0 md:top-auto right-0 bottom-0 left-[356px] overflow-hidden z-0 md:left-0 h-full'>
      <div className='relative top-0 w-full h-full xl:left-[300px] lg:left-[150px] sm:left-[180px]'>
        {STICKERS.map((sticker, index) => {
          const duration = Math.random() * 2 + 6;
          return (
            <motion.div
              key={sticker.key}
              className={`absolute ${sticker.className}`}
              initial={{ y: 900, rotate: index }}
              animate={{ y: -350, x: [0, 10, -10, 0], rotate: index * 0.5 + 180 }}
              transition={{
                repeat: Infinity,
                duration,
                delay: (duration / STICKERS.length) * index,
              }}>
              <Image
                className='scale-[0.9] lg:scale-[0.8]'
                key={sticker.key}
                src={sticker.src}
                alt={`Sticker ${index}`}
              />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
