import Image from 'next/image';
import { Typography } from '@/components';
import { FeaturesBackgroundLeft, FeaturesBackgroundRight } from '@/assets';
import { Sports } from './Sports';
import { Solution } from './Solution/Solution';
import { Markets } from './Markets/Markets';
import { Languages } from './Languages';

const Features = () => {
  return (
    <section
      id='features'
      className='relative text-neutral-0 pb-[330px] lg:pb-[186px] md:pb-0 md:w-full px-8 lg:px-6 overflow-visible'>
      <Image className='absolute left-0 z-10 -top-16 lg:hidden' src={FeaturesBackgroundLeft} alt=' ' />
      <Image className='absolute right-0 bottom-0 z-10 lg:hidden' src={FeaturesBackgroundRight} alt=' ' />
      <Typography className='text-center mb-[62px]' tag='h2' size='2xl'>
        More of our Key Features
      </Typography>
      <div className='max-w-[1120px] grid grid-cols-2 lg:grid-cols-1 lg-w-[auto] gap-x-16 gap-y-[134px] md:gap-y-[48px] mx-auto overflow-visible'>
        <Sports />
        <Solution />
        <Markets />
        <Languages />
      </div>
    </section>
  );
};

export default Features;
