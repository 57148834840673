'use client';

import React from 'react';
import { motion, VariantLabels, TargetAndTransition, AnimationControls } from 'framer-motion';

interface Props {
  tag?: 'div' | 'span' | 'section';
  className?: string;
  initial?: { [key: string]: number | string };
  whileInView?: VariantLabels | TargetAndTransition | undefined;
  animate?: boolean | VariantLabels | TargetAndTransition | AnimationControls | undefined;
  children?: React.ReactNode;
  viewportMargin?: string;
  id?: string;
}

const components = {
  div: motion.div,
  span: motion.span,
  section: motion.section,
};

const AnimatedComponent = React.forwardRef<HTMLDivElement, Props>(
  (
    { initial, whileInView, animate, className, children, id, tag = 'div', viewportMargin = '-70px 0% -100px 0%' },
    ref
  ) => {
    const MotionComponent = components[tag];

    return (
      <MotionComponent
        ref={ref}
        id={id}
        viewport={{ amount: 0.1, once: true, margin: viewportMargin }}
        className={className}
        initial={initial}
        whileInView={whileInView}
        animate={animate}>
        {children}
      </MotionComponent>
    );
  }
);

export default AnimatedComponent;
