import { SVGProps } from '@/interfaces/SVGProps';

const Expertise = ({ className, width = 24, height = 24 }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    className={className}>
    <g clipPath='url(#a)'>
      <path d='m23.602 8.338-11.25-6a.75.75 0 0 0-.705 0l-11.25 6a.75.75 0 0 0 0 1.324L3 11.05v4.54c-.001.368.135.724.38.998C4.61 17.956 7.36 20.25 12 20.25a12.186 12.186 0 0 0 4.5-.821v3.07a.75.75 0 1 0 1.5 0v-3.795a10.84 10.84 0 0 0 2.62-2.116c.245-.274.38-.63.38-.998v-4.54l2.602-1.388a.75.75 0 0 0 0-1.324ZM12 18.75c-4.057 0-6.443-1.982-7.5-3.16v-3.74l7.147 3.812a.75.75 0 0 0 .705 0L16.5 13.45v4.345c-1.181.55-2.67.955-4.5.955Zm7.5-3.164a9.17 9.17 0 0 1-1.5 1.336V12.65l1.5-.8v3.736Zm-1.875-4.435-.02-.013-5.25-2.8a.75.75 0 0 0-.706 1.324L16.031 12 12 14.15 2.344 9 12 3.85 21.656 9l-4.031 2.15Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default Expertise;
