'use client';

import Image from 'next/image';
import clsx from 'clsx';
import { useEffect } from 'react';
import { Button } from '@/components';
import { NavItem } from './NavItem';
import useScrolled from '@/hooks/useScrolled';
import { MainLogo, ArrowRight } from '@/assets';
import { NAV_ITEMS } from '@/constants/NavItems';

const Navbar = () => {
  const { isScrollingDown, isScrolled } = useScrolled();

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  return (
    <nav
      style={{ gridTemplateColumns: '1fr 134px 1fr' }}
      className={clsx(
        'text-neutral-0 pb-8 pt-12 grid items-center fixed top-0 left-0 right-0 z-[100] transition-all duration-200 ease-in-out md:hidden h-[104px]',
        isScrolled ? 'bg-neutral-900-transparent backdrop-blur-[19px] pt-8' : 'bg-transparent backdrop-blur-0',
        isScrollingDown && '-translate-y-full'
      )}>
      <ul className='flex items-center justify-end gap-10 lg:gap-6 mr-[88px] lg:mr-[30px]'>
        {NAV_ITEMS.LEFT.map(({ href, label }) => (
          <NavItem key={label} href={href} label={label} />
        ))}
      </ul>
      <div className='flex justify-center'>
        <Image src={MainLogo} width={134} height={40} alt='Splash Tech' />
      </div>
      <ul className='flex items-center justify-start gap-10 lg:gap-6 ml-[88px] lg:ml-[30px]'>
        {NAV_ITEMS.RIGHT.slice(0, 2).map(({ href, label }) => (
          <NavItem key={label} href={href} label={label} />
        ))}
        <a href='mailto:sales@splash.tech?subject=Hello!' target='_self' className='md:hidden rounded-full'>
          <Button rightIcon={ArrowRight}>Contact Us</Button>
        </a>
      </ul>
    </nav>
  );
};

export default Navbar;
