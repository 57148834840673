'use client';

import React, { MutableRefObject, Suspense, useState } from 'react';
import { Application } from '@splinetool/runtime';
import { ChooseGame } from './ChooseGame';

const CANVAS_ID = 'heroCanvas';
const CANVAS_URL = 'https://prod.spline.design/BRJVyqop1lemXXbQ/scene.splinecode';
const Spline = React.lazy(() => import('@splinetool/react-spline'));

interface Props {
  spline: MutableRefObject<Application | null>;
  gameStarted: boolean;
}

export const SplineCanvas = ({ spline, gameStarted }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleOnLoad = (loadedSpline: Application) => {
    spline.current = loadedSpline;
    setIsLoading(false);
  };

  return (
    <Suspense>
      <ChooseGame className='hidden md:block' isVisible={!gameStarted && !isLoading} />
      <Spline
        className='block z-10 bg-transparent pointer-events-none transition-scale duration-500 ease-in-out'
        onLoad={handleOnLoad}
        id={CANVAS_ID}
        scene={CANVAS_URL}
        renderOnDemand
      />
    </Suspense>
  );
};
