import Image from 'next/image';
import { FeaturePhone2Dark, FeaturePhone2Light } from '@/assets';
import { AnimatedComponent } from '@/components';

export const Phone = () => {
  return (
    <>
      <div className='absolute bottom-1 right-[53px] z-10 lg:max-w-[223px] lg:w-full lg:left-0 md:left-[20px] lg:right-0 lg:mx-auto'>
        <AnimatedComponent
          className='relative top-[2px]'
          animate={{
            opacity: [0, 0, 0, 1, 1, 1, 1, 0],
            transition: {
              duration: 3,
              repeat: Infinity,
              type: 'tween',
            },
          }}>
          <Image width={290} src={FeaturePhone2Dark} alt='Phone' />
        </AnimatedComponent>
        <AnimatedComponent
          className='absolute top-[2px] left-0'
          animate={{
            opacity: [1, 1, 1, 0, 0, 0, 0, 1],
            transition: {
              duration: 3,
              repeat: Infinity,
              type: 'tween',
            },
          }}>
          <Image width={290} src={FeaturePhone2Light} alt='Phone' />
        </AnimatedComponent>
      </div>

      <AnimatedComponent
        initial={{ opacity: 0, y: 20 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.8, type: 'spring', delay: 0 },
        }}
        animate={{
          borderColor: ['#81FDB7', '#81FDB7', '#000000', '#000000', '#000000', '#000000', '#81FDB7', '#81FDB7'],
          backgroundColor: ['#000000', '#000000', '#81FDB7', '#81FDB7', '#81FDB7', '#81FDB7', '#000000', '#000000'],
          transition: {
            duration: 3,
            repeat: Infinity,
            type: 'tween',
          },
        }}
        className='absolute z-30 bottom-[50px] right-[50px] lg:right-[130px] sm:right-[18%] xs:right-[12%] w-[64px] bg-neutral-900 border-secondary border-2 rounded-full p-[5px]'>
        <AnimatedComponent
          animate={{
            x: [0, 0, '110%', '110%', '110%', '110%', 0, 0],
            backgroundColor: ['#81FDB7', '#81FDB7', '#000000', '#000000', '#000000', '#000000', '#81FDB7', '#81FDB7'],
            transition: {
              duration: 3,
              repeat: Infinity,
              type: 'tween',
            },
          }}
          className='w-[24px] h-[24px] rounded-full bg-secondary'
        />
      </AnimatedComponent>
    </>
  );
};
