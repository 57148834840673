'use client';

import { Application } from '@splinetool/runtime';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { Button, Icon } from '@/components';
import { SplineCanvas } from './SplineCanvas';
import { Game } from './Game';
import { ChooseGame } from './ChooseGame';
import { ArrowRight } from '@/assets';
import animateSplineVariable from '@/utils/animateSplineVariable';
import { GameType, STEPS } from './utils';

const INITIAL_SCALE = 1.15;
const HOVER_SCALE = 1.18;
const OPACITY_ANIMATION_DURATION = 100;
const SCALE_ANIMATION_DURATION = 200;
const EVENT_OBJECTS = {
  SPORTS: '4ce6f758-5490-4063-9428-843148f933f6',
  CASINO: 'fad6d1dc-ea2d-41a7-8429-ecf92b45bacb',
};

export const Phones = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isHoveringSports, setIsHoveringSports] = useState(false);
  const [isHoveringCasino, setIsHoveringCasino] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [selectedGame, setSelectedGame] = useState<GameType>(GameType.CASINO);
  const [isReadyToStart, setIsReadyToStart] = useState(true);
  const [showGameScreen, setShowGameScreen] = useState(false);
  const spline = useRef<Application | null>(null);

  const animateVariable = (variableName: string, endValue: number, duration: number) => {
    if (!spline.current) return;

    animateSplineVariable({
      spline,
      variableName,
      startValue: Number(spline.current.getVariable(variableName)),
      endValue,
      duration,
    });
  };

  const handleStartEndGame = (updatedSelectedGame: GameType) => {
    setGameStarted(!gameStarted);

    const variablesToAnimate = {
      SPORTS: {
        elementsOpacity: 'sportsElementsOpacity',
        elementsShadow: 'casinoElementsShadow',
      },
      CASINO: {
        elementsOpacity: 'casinoElementsOpacity',
        elementsShadow: 'sportsElementsShadow',
      },
    };

    const { elementsOpacity, elementsShadow } = variablesToAnimate[updatedSelectedGame];

    if (gameStarted) {
      spline.current?.emitEventReverse('mouseDown', EVENT_OBJECTS[updatedSelectedGame]);
      animateVariable(elementsShadow, 0, 800);
      animateVariable('casinoScale', INITIAL_SCALE, 800);
    } else {
      setIsReadyToStart(false);
      spline.current?.emitEvent('mouseDown', EVENT_OBJECTS[updatedSelectedGame]);
      animateVariable(elementsOpacity, 0, OPACITY_ANIMATION_DURATION);
      animateVariable(elementsShadow, 70, 800);

      if (window.innerWidth <= 769) {
        window.scrollTo({
          top: 200,
          behavior: 'smooth',
        });
      }
    }

    setTimeout(() => {
      setShowGameScreen(!gameStarted);

      if (gameStarted) {
        setIsReadyToStart(true);
        animateVariable(elementsOpacity, 100, OPACITY_ANIMATION_DURATION);
        setActiveStep(1);
      }
    }, 700);
  };

  const handleSports = () => {
    setSelectedGame(GameType.SPORTS);
    handleStartEndGame(GameType.SPORTS);
  };

  const handleCasino = () => {
    setSelectedGame(GameType.CASINO);
    handleStartEndGame(GameType.CASINO);
  };

  const onAdvanceStep = (nextStep: number) => {
    if (selectedGame === GameType.SPORTS && nextStep > 4) {
      handleStartEndGame(selectedGame);
      return;
    }

    if (selectedGame === GameType.CASINO && nextStep > 3) {
      handleStartEndGame(selectedGame);
      return;
    }

    setActiveStep(prevStep => Math.min(prevStep + 1, STEPS[selectedGame].length));
  };

  const handleHoverEnter = (gameType: GameType) => {
    let variableName = '';
    if (gameType === GameType.SPORTS) {
      setIsHoveringSports(true);
      variableName = 'sportsScale';
    } else {
      setIsHoveringCasino(true);
      variableName = 'casinoScale';
    }

    if (!gameStarted && spline.current) {
      animateSplineVariable({
        spline,
        variableName,
        startValue: Number(spline.current.getVariable(variableName)),
        endValue: HOVER_SCALE,
        duration: SCALE_ANIMATION_DURATION,
      });
    }
  };

  const handleHoverLeave = (gameType: GameType) => {
    let variableName = '';
    if (gameType === GameType.SPORTS) {
      setIsHoveringSports(false);
      variableName = 'sportsScale';
    } else {
      setIsHoveringCasino(false);
      variableName = 'casinoScale';
    }

    if (!gameStarted && spline.current) {
      animateSplineVariable({
        spline,
        variableName,
        startValue: Number(spline.current.getVariable(variableName)),
        endValue: INITIAL_SCALE,
        duration: SCALE_ANIMATION_DURATION,
      });
    }
  };

  const currentStepData = STEPS[selectedGame].find(step => step.key === activeStep);

  return (
    <div>
      <Button
        className={clsx(
          'text-neutral-0 absolute z-20 right-0  mx-auto transition-opacity duration-200 ease-in-out',
          showGameScreen && gameStarted ? 'opacity-100' : 'opacity-0 pointer-events-none',
          selectedGame === GameType.CASINO
            ? 'top-[60px] md:top-[44px] -left-[390px] md:-left-[210px]'
            : 'top-[60px] -left-[390px] md:-right-[160px]'
        )}
        size='small'
        onClick={() => handleStartEndGame(selectedGame)}
        variant='icon'>
        <Icon className='fill-neutral-0 rotate-180' Component={ArrowRight} width={24} height={24} />
      </Button>

      <div
        className={clsx(
          'absolute inset-0 flex justify-center items-center -top-[85px] md:-top-[50px] transition-all duration-700 ease-in-out'
        )}>
        <div
          role='button'
          tabIndex={0}
          onClick={handleSports}
          onKeyDown={handleSports}
          onMouseEnter={() => handleHoverEnter(GameType.SPORTS)}
          onMouseLeave={() => handleHoverLeave(GameType.SPORTS)}
          aria-label='Start game'
          className={clsx(
            'absolute focus:outline-none right-0 mx-auto inset-0 flex justify-center items-center top-[60px] md:top-[50px] w-[360px] h-[580px] md:h-[550px] -left-[270px]',
            !isReadyToStart && 'pointer-events-none'
          )}>
          <ChooseGame
            className='top-0 right-[50px] z-50 block md:hidden'
            isVisible={!gameStarted && isHoveringSports}
          />
        </div>
        <div
          role='button'
          tabIndex={0}
          onClick={handleCasino}
          onKeyDown={handleCasino}
          onMouseEnter={() => handleHoverEnter(GameType.CASINO)}
          onMouseLeave={() => handleHoverLeave(GameType.CASINO)}
          aria-label='Start game'
          className={clsx(
            'absolute focus:outline-none mx-auto inset-0 flex justify-center items-center top-[100px] md:top-[80px] w-[300px] h-[510px] -right-[420px]',
            !isReadyToStart && 'pointer-events-none'
          )}>
          <ChooseGame
            className='top-[200px] right-[10px] z-50 block md:hidden'
            isVisible={!gameStarted && isHoveringCasino}
          />
        </div>

        <SplineCanvas gameStarted={gameStarted} spline={spline} />
      </div>

      <Game
        showGameScreen={showGameScreen}
        gameStarted={gameStarted}
        onAdvanceStep={onAdvanceStep}
        currentStepData={currentStepData}
        activeStep={activeStep}
        selectedGame={selectedGame}
      />
    </div>
  );
};
