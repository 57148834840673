import clsx from 'clsx';
import { Button, Icon, Typography } from '@/components';
import { SVGProps } from '@/interfaces/SVGProps';
import { Cross, Content, Data, Expertise } from '@/assets';
import { HeadingKey } from './utils';

interface Props {
  selectedHeading: HeadingKey;
  selectedDescription: string;
  isDescriptionVisible: boolean;
  handleCloseDescription: () => void;
}

const containerClasses: { [key in HeadingKey]: string } = {
  expertise: 'top-[290px] left-[520px] lg:top-[42%] md:top-[32%]',
  data: 'top-[315px] left-[520px] lg:top-[44%] lg:left-[440px] lg:right-[50px] md:top-[60%]',
  content: 'top-[295px] left-[520px] lg:top-[38%] md:top-[28%]',
};

const iconClasses: { [key in HeadingKey]: string } = {
  expertise: '-top-6 left-[40px]',
  data: 'top-8 -left-6 md:-top-6 md:left-[40px]',
  content: '-bottom-6 left-[40px]',
};

const icon: { [key in HeadingKey]: (props: SVGProps) => React.ReactElement } = {
  expertise: Expertise,
  data: Data,
  content: Content,
};

export const Description = ({
  selectedHeading,
  isDescriptionVisible,
  selectedDescription,
  handleCloseDescription,
}: Props) => (
  <div
    className={clsx(
      'absolute z-20 p-[2px] w-[474px] lg:left-[110px] lg:w-auto lg:max-w-[400px] md:right-6 md:left-6 bg-gradient-to-tr from-primary to-secondary rounded-3xl transition-all duration-500 ease-in-out',
      isDescriptionVisible ? 'opacity-100 translate-x-0' : 'translate-x-[300px] opacity-0 pointer-events-none',
      isDescriptionVisible ? containerClasses[selectedHeading] : 'top-[295px] left-[700px]'
    )}>
    <div className='relative bg-neutral-900 px-[48px] py-[40px] md:px-[32px] rounded-3xl'>
      <div className={clsx('bg-secondary rounded-full p-[12px] w-12 h-12 absolute', iconClasses[selectedHeading])}>
        <Icon width={24} height={24} Component={icon[selectedHeading]} className='fill-neutral-900' />
      </div>
      <Button
        variant='icon'
        className='absolute top-0 right-0 md:right-2'
        onClick={handleCloseDescription}
        hideContainer>
        <Icon
          width={24}
          height={24}
          Component={Cross}
          className='fill-neutral-0 hover:fill-secondary transition-fill duration-200 ease-in-out'
        />
      </Button>
      <Typography tag='p' size='bodyMedium'>
        {selectedDescription}
      </Typography>
    </div>
  </div>
);
