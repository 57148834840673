import Image from 'next/image';
import { AnimatedComponent } from '@/components';
import { Feature } from './Feature';
import {
  FeaturePhone4,
  LanguageBadge,
  LanguageDropdown,
  England,
  Finland,
  France,
  Germany,
  India,
  Italy,
  Japan,
  Kenya,
  Netherlands,
  Norway,
  Poland,
  Portugal,
  SaudiArabia,
  Spain,
  Ukraine,
  USA,
} from '@/assets';

const FLAGS = [
  { name: 'United States of America', src: USA },
  { name: 'England', src: England },
  { name: 'Spain', src: Spain },
  { name: 'Portugal', src: Portugal },
  { name: 'Italy', src: Italy },
  { name: 'France', src: France },
  { name: 'Germany', src: Germany },
  { name: 'Netherlands', src: Netherlands },
  { name: 'Poland', src: Poland },
  { name: 'Ukraine', src: Ukraine },
  { name: 'Norway', src: Norway },
  { name: 'Finland', src: Finland },
  { name: 'India', src: India },
  { name: 'Kenya', src: Kenya },
  { name: 'Saudi Arabia', src: SaudiArabia },
  { name: 'Japan', src: Japan },
];

export const Languages = () => (
  <Feature
    className='bg-gradient-to-r from-primary-100 to-primary md:from-secondary md:to-primary-200'
    title='16 Languages'>
    <div className='absolute top-0 right-0 left-0 bottom-[2px] w-full overflow-hidden'>
      <Image
        className='absolute bottom-0 lg:bottom-0 left-[70px] z-0 lg:w-[250px] md:w-[260px] md:left-[30px]'
        width={306}
        src={FeaturePhone4}
        alt='Phone'
      />
    </div>
    <AnimatedComponent
      className='absolute bottom-[185px] left-[120px] xl:left-[40px] lg:w-[95px] lg:bottom-[190px] lg:left-[80px] md:left-[40px] md:bottom-[150px]'
      initial={{ opacity: 0, scale: 0.8 }}
      whileInView={{ opacity: 1, scale: 1, transition: { duration: 0.8, type: 'spring' } }}
      animate={{
        y: [0, 10, 0],
        transition: {
          duration: 3,
          repeat: Infinity,
          delay: 1,
        },
      }}>
      <Image width={111} src={LanguageBadge} alt='Language' />
    </AnimatedComponent>

    <Image
      className='absolute top-[145px] right-[50px] rounded-[9px] lg:w-[270px] lg:top-[150px] lg:right-[80px] lg:rounded-[7px] md:rounded-[5px]
            md:w-[206px] md:top-[100px] md:right-[40px]'
      width={335}
      src={LanguageDropdown}
      alt='Language Dropdown'
    />

    <AnimatedComponent
      className='absolute flex flex-wrap gap-[24px] md:gap-[14px] bottom-[70px] -right-[50px] p-[32px] rounded-[8px] xl:right-[20px] lg:bottom-[50px] md:bottom-[130px] lg:-right-[70px] max-w-[337px] md:max-w-[200px]
           md:p-[10px] md:-right-[20px] backdrop-blur-[10px] bg-neutral-0-transparent-25 border-solid border-[1px] border-neutral-0-transparent-25'
      initial={{ opacity: 0, translateY: -20 }}
      whileInView={{ opacity: 1, translateY: 0, transition: { duration: 0.8, type: 'spring', delay: 0.2 } }}
      animate={{
        y: [0, 10, 0],
        transition: {
          duration: 5,
          repeat: Infinity,
          delay: 1,
        },
      }}>
      {FLAGS.map(({ name, src }) => (
        <Image className='w-[25px] md:w-[15px] h-[25px] md:h-[15px]' key={name} src={src} alt={name} />
      ))}
    </AnimatedComponent>
  </Feature>
);
